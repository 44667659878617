<template>
    <b-card border-variant="primary" header-bg-variant="primary">
        <template #header>
            <b-row align-v="center">
                <b-col cols="10">
                    <h6 class="text-white mb-0">{{ $t('admin.bundles.phase') }} {{ index }}</h6>
                </b-col>
                <b-col cols="2" class="text-end">
                    <b-form-checkbox v-model="bundlePhase.optional" switch class="text-white mb-0">
                        {{ $t('admin.bundles.optional') }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </template>
        <b-card-text>
            <b-row class="mb-3">
                <b-col cols="3">Percentages for the Phase:</b-col>
                <b-col cols="9">
                    <b-input-group
                        :prepend="`O: ${percentageOrigin.toString()}%`"
                        :append="`D: ${(100 - percentageOrigin).toString()}%`"
                    >
                        <b-form-input
                            v-model="percentageOrigin"
                            id="range-2"
                            number
                            type="range"
                            min="0"
                            max="100"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-alert variant="danger" :show="showProductAlert">
                <b-icon icon="exclamation-circle-fill" font-scale="1" class="mr-3"></b-icon>
                {{ $t('admin.bundles.alert') }}
            </b-alert>
            <b-table
                :items="bundlePhase.products"
                :fields="fields"
                responsive
                small
                bordered
                class="mb-5"
            >
                <template #thead-top>
                    <b-tr>
                        <b-th colspan="2" class="text-center">
                            {{ $t('admin.bundles.branches') }}
                        </b-th>
                        <b-th colspan="2" class="text-center">
                            {{ $t('admin.bundles.percentages') }}
                        </b-th>
                    </b-tr>
                </template>
                <template #head()="{label}">
                    <p class="text-center align-middle">{{ label }}</p>
                </template>
                <template #cell(isSingleBusiness)="{item}">
                    <b-icon
                        :icon="item.isSingleBusiness ? 'check-lg' : 'x-lg'"
                        :variant="item.isSingleBusiness ? 'success' : 'danger'"
                        class="icon"
                    ></b-icon>
                </template>
                <template #cell(originBranches)="{item}">
                    <ul class="list-inline d-inline-block mb-2">
                        <li v-for="tag in item.originBranches" :key="tag" class="list-inline-item">
                            <b-form-tag :title="tag" variant="warning" no-remove>
                                {{ tag }}
                            </b-form-tag>
                        </li>
                    </ul>
                </template>
                <template #cell(destinationBranches)="{item}">
                    <ul class="list-inline d-inline-block mb-2">
                        <li
                            v-for="tag in item.destinationBranches"
                            :key="tag"
                            class="list-inline-item"
                        >
                            <b-form-tag :title="tag" variant="info" no-remove>
                                {{ tag }}
                            </b-form-tag>
                        </li>
                    </ul>
                </template>
                <template #cell(price)="{ item }">
                    {{ getCurrency(item) }}
                </template>
                <template #cell(actions)="{item}">
                    <b-button
                        variant="danger"
                        size="lg"
                        class="p-0"
                        @click="deleteProduct(item.productName)"
                    >
                        <b-icon icon="trash" font-scale="1"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <BundleProduct @product="setProduct" v-for="n in productQuantity" :key="n" />
        </b-card-text>
    </b-card>
</template>

<script>
import { imGetRequest } from '../../../api/imRequests'
import BundleProduct from './BundleProduct.vue'

export default {
    name: 'BundlePhase',
    components: {
        BundleProduct
    },
    props: {
        quantity: {
            type: Number,
            default: 1
        },
        index: {
            type: Number,
            required: true
        },
        editingPhases: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showProductAlert: false,
            productQuantity: 1,
            percentageOrigin: 0,
            centralProducts: [],
            bundlePhase: {
                phaseName: `${this.$t('admin.bundles.phase')} ${this.index}`,
                optional: false,
                products: []
            },
            fields: [
                { key: 'originBranches', label: this.$t('admin.bundles.origin') },
                { key: 'destinationBranches', label: this.$t('admin.bundles.destination') },
                { key: 'percentageOrigin', label: this.$t('admin.bundles.origin') },
                { key: 'percentageDestination', label: this.$t('admin.bundles.destination') },
                { key: 'isSingleBusiness', label: this.$t('admin.bundles.single') },
                { key: 'productName', label: this.$t('admin.bundles.productName') },
                { key: 'price', label: this.$t('admin.bundles.price') },
                { key: 'actions', label: this.$t('admin.bundles.actions') }
            ]
        }
    },
    created() {
        this.getCentralProducts()
    },
    mounted() {
        if (this.editingPhases && this.quantity === this.editingPhases.length) {
            this.bundlePhase = this.editingPhases[this.index - 1]
            this.percentageOrigin = this.editingPhases[this.index - 1].products[0].percentageOrigin
        }
    },
    watch: {
        percentageOrigin(newValue) {
            if (this.bundlePhase.products.length) {
                this.bundlePhase.products.forEach(product => {
                    if (!product.isSingleBusiness) {
                        product.percentageOrigin = newValue
                        product.percentageDestination = 100 - this.percentageOrigin
                    }
                })
            }
        }
    },
    methods: {
        async getCentralProducts() {
            const country = sessionStorage.getItem('countryBundle') === 'US' ? 43 : 38
            const { data } = await imGetRequest(`bundles/products?categoryId=${country}`)
            this.centralProducts = data.sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
        },
        getCurrency(product) {
            if (this.centralProducts.length) {
                const { moneda_id, prod_preciolista } = this.centralProducts.find(
                    cp => cp.prod_id === product.id
                )

                const currency = moneda_id === 1 ? 'MXN' : 'USD'
                const productvalue = prod_preciolista.toLocaleString(
                    moneda_id === 1 ? 'es-MX' : 'en-US',
                    {
                        style: 'currency',
                        currency
                    }
                )

                return `${currency} ${productvalue}`
            }
        },
        setProduct(product) {
            this.showProductAlert = false
            const prod = this.bundlePhase.products.find(prod => prod.prod_id === product.id)

            if (!prod) {
                let newProduct = { ...product }

                if (!newProduct.isSingleBusiness) {
                    newProduct.percentageOrigin = this.percentageOrigin
                    newProduct.percentageDestination = 100 - this.percentageOrigin
                }

                this.bundlePhase.products.push({ ...newProduct })
            } else {
                this.showProductAlert = true
            }
            this.updatePhase()
        },
        deleteProduct(pName) {
            this.bundlePhase.products = this.bundlePhase.products.filter(
                p => p.productName !== pName
            )
        },
        updatePhase() {
            this.$emit('updatePhase', { ...this.bundlePhase, index: this.index })
        }
    }
}
</script>
